import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import Layout from '../layout/Layout'
import config from '../config/theme'
import SectionHeader from '../components/SectionHeader'
import SectionBenefits from '../components/SectionBenefits'
import {
  SectionRecruitmentProcess,
  SectionCareerSlider,
  SectionOpinionsSlider,
  SectionTeam,
} from '../components/Career'
import SectionFileForm from '../components/SectionFileForm'

export default function Home() {
  const {
    allMarkdownRemark,
    headerBgMobile,
    headerBg,
    sectionBgMobile,
    sectionBg,
  } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___id], order: ASC }
          filter: { fileAbsolutePath: { regex: "//career//" } }
        ) {
          edges {
            node {
              frontmatter {
                id
                title
                slug
                thumbnail {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        headerBgMobile: file(
          relativePath: { eq: "kariera/kariera-bg-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        headerBg: file(relativePath: { eq: "kariera/header2.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sectionBgMobile: file(
          relativePath: { eq: "kariera/section-bg-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sectionBg: file(relativePath: { eq: "kariera/section-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  const headerImages = [
    { ...getImage(headerBgMobile) },
    {
      ...getImage(headerBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  const sectionImages = [
    { ...getImage(sectionBgMobile) },
    {
      ...getImage(sectionBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  return (
    <Layout
      metaTitle="Nordic Team"
      metaDescription="Wsparcie w sukcesie Twojego biznesu jest najważniejszym celem, któremu podporządkowujemy współpracę. "
      slug="/kariera"
      before="Nordic"
      after="Team"
    >
      <SectionHeader
        smallTitle="Kariera"
        title={
          <>
            Nordic <br />
            <span className="c-primary">Team</span>
          </>
        }
        titleBorder="white"
        lead={
          <>
            Kluczem do sukcesu są ludzie, to oni tworzą najwyższe standardy
            i&nbsp;dokonują rzeczy niemalże niemożliwych. Wśród nas są ludzie
            z&nbsp;doświadczeniem, ale i&nbsp;tacy, którzy spedycji nauczyli się
            od najlepszych.
          </>
        }
        scroll="#aplikuj"
        buttonText="Aplikuj"
        bgImage={headerImages}
      />
      <SectionRecruitmentProcess />
      <SectionCareerSlider items={allMarkdownRemark.edges} />
      <SectionBenefits />
      <SectionTeam />
      <SectionOpinionsSlider
        bgImage={sectionImages}
        items={[
          {
            name: 'Maciej',
            job: 'Młodszy lider ds. sprzedaży i spedycji',
            image: <StaticImage src="../images/kariera/Maciej.png" alt="" />,
            content: (
              <>
                Ponad 2 lata temu podczas szukania nowej pracy oczekiwałem
                lepszej zmiany. Zmiana na Nordic Logistics okazała się
                strzałem&nbsp;w dziesiątkę! Pracodawca dba o&nbsp;bardzo dobre
                warunki finansowe oraz przyjazną atmosferę, której ciężko
                doświadczyć w&nbsp;dużych korporacjach. W&nbsp;kwestii możliwość
                rozwoju jestem idealnym przykładem pracownika, który swoją pracę
                w&nbsp;Nordic zaczynał od stanowiska młodszego spedytora,
                a&nbsp;niedawno awansowałem na lidera grupy młodszych stażem
                pracowników. Wszyscy którzy się zastanawiają nad złożeniem
                aplikacji z&nbsp;czystym sumieniem mogę polecić! Jestem
                przekonany, że gdybym miał możliwość złożyłbym i&nbsp;drugi raz!
              </>
            ),
          },
          {
            name: 'Asia',
            job: 'Spedytor krajowy i międzynarodowy',
            image: <StaticImage src="../images/kariera/Joanna.png" alt="" />,
            content: (
              <>
                Kiedyś pracowałam w korpo, ludzie anonimowi, mimo że mijasz się
                z&nbsp;nimi na korytarzu nie znasz tak naprawdę nikogo, tu od
                pierwszego dnia czułam się jakbym wszystkich znała od zawsze.
                Serdeczni, pomocni - nawet głupie pytania nigdy nie były głupie.
                Zawsze jest ktoś pod ręką gotowy do pomocy. Dobra podstawa
                i&nbsp;świetne premie, nie tylko pieniężne (vouchery do
                restauracji, galerii albo salonów kosmetycznych), pakiet
                medyczny dla mnie i&nbsp;dla mojej rodziny. Sama praca ciekawa -
                codziennie inne kierunki, nowi klienci - a&nbsp;wszystko tak
                dynamiczne, że dzień mija nie wiadomo kiedy. Nie zamieniłabym
                tej pracy na żadną inną.
              </>
            ),
          },
          {
            name: 'Łukasz',
            job: 'Specjalista ds. personalnych i administracji',
            image: <StaticImage src="../images/kariera/Lukasz.png" alt="" />,
            content: (
              <>
                W Nordic Logistics pracuje od 2020 roku, firma z&nbsp;roku na
                rok prężnie rozwija się pod każdym względem. Mogę zapewnić, że
                praca jest ciekawa, dynamiczna i&nbsp;codziennie uczymy się
                czegoś nowego, a&nbsp;tak pozytywnej atmosfery i&nbsp;tak
                zgranego zespołu pozazdrościć nam może niejedna firma.
              </>
            ),
          },
          {
            name: 'Justyna',
            job: 'Spedytor krajowy i międzynarodowy',
            image: <StaticImage src="../images/kariera/Justyna.png" alt="" />,
            content: (
              <>
                Jako pracownik polecam pracę w&nbsp;Nordicu z&nbsp;kilku
                prostych powodów, mianowicie dobrego wynagrodzenia
                i&nbsp;pozytywnej atmosfery- to dwa najważniejsze czynniki,
                które często wpływają na decyzję o&nbsp;zamianie pracy.
                Dodatkowo cenię sobie indywidualne podejście do pracownika
                i&nbsp;szkolenia. Mamy młody zespół, który chce się rozwijać,
                a&nbsp;dodatkowy plus to konkursy, fajna atmosfera, gifty np.
                w&nbsp;postaci zdrowych przekąsek, czy wypadów na pizzę. Dla
                młodych ludzi czego chcieć więcej?
              </>
            ),
          },
          {
            name: 'Patrycja',
            job: 'Dyrektor ds. sprzedaży i spedycji',
            image: <StaticImage src="../images/kariera/Patrycja.png" alt="" />,
            content: (
              <>
                Pracę w Nordic Logistics Polska rozpoczęłam w&nbsp;2018 roku.
                Moim głównym priorytetem było stworzenie zespołu, w&nbsp;którym
                z&nbsp;jednej strony znajdą się profesjonaliści, ale
                i&nbsp;ludzie tworzący świetną atmosferę. Dzisiaj mogę
                powiedzieć, że odnieśliśmy wielki sukces. Nordic to nie tylko
                duże możliwości rozwoju i&nbsp;życzliwi ludzie, to przede
                wszystkim stabilne miejsce pracy. My – pracownicy Nordic
                Logistics działamy na siebie jak Perpetuum mobile i&nbsp;jestem
                przekonana, że razem możemy zdziałać jeszcze więcej.
              </>
            ),
          },
        ]}
      />
      <SectionFileForm items={allMarkdownRemark.edges} />
    </Layout>
  )
}
